<template>
  <onboarding-layout back-route-name="OnboardingAvatar">
    <h1 class="h2 mb-2">
      Wat is je e-mailadres?
    </h1>

    <div class="email-container">
      <p class="color-text mb-3">
        Je e-mailadres wordt gebruikt om een account voor je aan te maken.
      </p>

      <form action="" method="POST" @submit.prevent="onSubmit">
        <Errors :errors="errors" />

        <div class="mb-2">
          <label class="visually-hidden" for="email">
            Vul hier je e-mailadres in
          </label>
          <input
            class="form-control form-control--center-placeholder"
            type="email"
            name="email"
            id="email"
            required
            v-model="formEmail"
            placeholder="Vul hier je e-mailadres in"
          >
        </div>

        <div class="mb-2">
          <input
            type="checkbox"
            name="agreement"
            id="agreement"
            value="1"
            v-model="formAgreement"
          >
          <label class="color-text" for="agreement">
            Ik ga akkoord met de <a class="color-text" href="/algemene-voorwaarden">voorwaarden</a>
            en <a class="color-text" href="/privacy-policy">privacy policy</a>
          </label>
        </div>

        <Button
          button-type="submit"
          :disabled="registerLoadingState === 'loading'"
          :show-arrow="false"
        >
          {{ registerLoadingState === 'loading'
            ? 'Een moment geduld...'
            : 'Ja, maak mijn account aan'
          }}
        </Button>
      </form>
    </div>
  </onboarding-layout>
</template>

<script>
import { mapGetters } from 'vuex';
import OnboardingLayout from './OnboardingLayout.vue';
import Errors from '../../components/Errors.vue';
import retrieveErrorsFromResponse from '../../helpers/retrieveErrorsFromResponse';
import Button from '../../components/Button.vue';

export default {
  components: {
    Button,
    Errors,
    OnboardingLayout,
  },

  data() {
    return {
      errors: [],
      formEmail: this.$store.state.onboarding.email,
      formAgreement: this.$store.state.onboarding.agreement,
    };
  },

  created() {
    const {
      petName,
      petType,
      avatar,
      firstName,
      lastName,
    } = this.$store.state.onboarding;

    if (!petName || !petType || !avatar || !firstName || !lastName) {
      this.$router.push({
        name: 'OnboardingPetType',
      });
      return;
    }

    this.$store.commit('resetLayout', {
      showDog: this.petType === 'dog',
      showCat: this.petType === 'cat',
      showCta: false,
      layoutAvatar: this.avatar,
      progress: 98,
    });
  },

  mounted() {
    this.$gtm.trackEvent({
      event: 'onboarding',
      category: 'Onboarding',
      action: 'step',
      label: '6-user-email',
    });
  },

  methods: {
    validate() {
      this.errors = [];

      if (!this.formEmail) {
        this.errors.push('Geef een e-mailadres op');
      }

      if (!this.formAgreement) {
        this.errors.push('Je moet akkoord gaan met de algemene voorwaarden');
      }

      return this.errors.length === 0;
    },

    onSubmit() {
      if (!this.validate()) {
        return;
      }

      this.$store.commit('setEmail', this.formEmail);
      this.$store.commit('setAgreement', this.formAgreement);

      this.$store.dispatch('register')
        .then(() => {
          this.$router.push({ name: 'OnboardingComplete' });
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err);

          if (!err.response) {
            this.errors = ['Er ging iets mis. Probeer het later opnieuw.'];
            return;
          }

          this.errors = retrieveErrorsFromResponse(err.response);
        });
    },
  },

  computed: {
    ...mapGetters([
      'registerLoadingState',
    ]),

    petType() {
      return this.$store.state.onboarding.petType;
    },

    avatar() {
      return this.$store.state.onboarding.avatar;
    },
  },
};
</script>

<style>
.email-container {
  max-width: 25rem;
  margin-left: auto;
  margin-right: auto;
}
</style>
